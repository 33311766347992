
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'SummaryCountriesSearch',
  computed: {
    ...mapGetters('Summary', ['orderOptions']),
    search: {
      get () {
        return this.$store.state.Summary.search
      },
      set (value: string): void {
        this.$store.commit('Summary/SET_SEARCH', value)
      }
    },
    order: {
      get () {
        return this.$store.state.Summary.order
      },
      set (value: string): void {
        this.$store.commit('Summary/SET_ORDER', value)
      }
    }
  }
})
