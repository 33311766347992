
import Vue from 'vue'

export default Vue.extend({
  name: 'CardStatistic',
  methods: {
    toToRoute () {
      if (this.route) {
        this.$router.push(this.route)
      }
    }
  },
  props: {
    label: {
      type: String,
      required: true
    },
    number: {
      type: Number,
      default: 0
    },
    appendNumber: {
      type: String,
      default: ''
    },
    route: {
      type: Object,
      default: null
    }
  }
})
