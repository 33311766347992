
import Vue from 'vue'

export default Vue.extend({
  name: 'LoadDataError',
  methods: {
    refresh (): void {
      this.$emit('refresh')
    }
  },
  props: {
    error: {
      type: Object,
      default: () => ({
        id: null,
        title: null,
        status: false,
        message: null
      })
    }
  }
})
