
import Vue from 'vue'
import Loading from '@/components/atoms/Loading.vue'
import CountryStatusContent from './CountryStatusContent.vue'
import LoadDataError from '@/components/atoms/LoadDataError.vue'
import Covid19APIFooterVue from '@/components/atoms/Covid19APIFooter.vue'
import { mapState, mapActions } from 'vuex'

export default Vue.extend({
  name: 'CountryStatus',
  data: () => ({
    dialog: true
  }),
  computed: {
    ...mapState('CountryStatus', ['loading', 'error'])
  },
  methods: {
    ...mapActions('CountryStatus', ['getCountryStatusConfirmedDataFromApi', 'clearCountryStatus']),
    closeDialog (): void {
      this.dialog = false
      setTimeout(() => {
        this.$router.push({ name: 'summary' })
      }, 100)
    },
    getCountryStatusConfirmed (): void {
      this.getCountryStatusConfirmedDataFromApi(this.$route.params.country)
    }
  },
  mounted (): void {
    this.getCountryStatusConfirmed()
  },
  destroyed (): void {
    this.clearCountryStatus()
  },
  components: {
    Loading,
    LoadDataError,
    Covid19APIFooterVue,
    CountryStatusContent
  }
})
