import { render, staticRenderFns } from "./CountryStatusContent.vue?vue&type=template&id=45da2e34&scoped=true&"
import script from "./CountryStatusContent.vue?vue&type=script&lang=ts&"
export * from "./CountryStatusContent.vue?vue&type=script&lang=ts&"
import style0 from "./CountryStatusContent.vue?vue&type=style&index=0&id=45da2e34&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45da2e34",
  null
  
)

export default component.exports