
import Vue from 'vue'
import { mapState, mapActions } from 'vuex'
import LoadDataError from '@/components/atoms/LoadDataError.vue'
import Loading from '@/components/atoms/Loading.vue'
import SummaryCountriesList from './SummaryCountriesList.vue'
import SummaryCountriesSearch from './SummaryCountriesSearch.vue'
import Covid19APIFooterVue from '@/components/atoms/Covid19APIFooter.vue'

export default Vue.extend({
  name: 'Summary',
  computed: {
    ...mapState('Summary', ['loading', 'error'])
  },
  methods: {
    ...mapActions('Summary', ['getSummaryDataFromApi'])
  },
  mounted () {
    this.getSummaryDataFromApi()
  },
  components: {
    LoadDataError,
    Loading,
    SummaryCountriesList,
    SummaryCountriesSearch,
    Covid19APIFooterVue
  }
})
