
import Vue from 'vue'
import { COUNTRY_STATUS_ROUTER_NAME } from '@/router/routers/countryStatus'
import { mapState, mapGetters, mapActions } from 'vuex'
import SummaryCountriesListNotFound from './SummaryCountriesListNotFound.vue'
import CardStatistic from '@/components/atoms/CardStatistic.vue'

export default Vue.extend({
  name: 'SummaryCountriesList',
  computed: {
    ...mapState('Summary', ['perPage']),
    ...mapGetters('Summary', ['countriesFormated'])
  },
  methods: {
    ...mapActions('Summary', ['loadMoreCountries']),
    getCountryRoute (countrySlug: string) {
      return {
        name: COUNTRY_STATUS_ROUTER_NAME,
        params: {
          country: countrySlug
        }
      }
    }
  },
  components: {
    CardStatistic,
    SummaryCountriesListNotFound
  }
})
