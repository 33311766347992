
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'SummaryCountriesListNotFound',
  computed: {
    ...mapGetters('Summary', ['countriesFormated'])
  }
})
