export default JSON.parse(`[
  {
    "ID": "02cbb43f-4225-46d7-a7d1-a14bd314594d",
    "Country": "Afghanistan",
    "CountryCode": "AF",
    "Slug": "afghanistan",
    "NewConfirmed": 67,
    "TotalConfirmed": 205391,
    "NewDeaths": 0,
    "TotalDeaths": 7833,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "ee813600-02d9-4650-9216-241da66f49ea",
    "Country": "Albania",
    "CountryCode": "AL",
    "Slug": "albania",
    "NewConfirmed": 11,
    "TotalConfirmed": 333293,
    "NewDeaths": 0,
    "TotalDeaths": 3594,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "34c48c32-e7e0-4258-8bf7-e40499d284ec",
    "Country": "Algeria",
    "CountryCode": "DZ",
    "Slug": "algeria",
    "NewConfirmed": 6,
    "TotalConfirmed": 271041,
    "NewDeaths": 0,
    "TotalDeaths": 6881,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "d01d248f-0b9d-4d3c-836e-c08109476525",
    "Country": "Andorra",
    "CountryCode": "AD",
    "Slug": "andorra",
    "NewConfirmed": 0,
    "TotalConfirmed": 46824,
    "NewDeaths": 0,
    "TotalDeaths": 156,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "77742177-25ab-439f-b08b-336720b7a847",
    "Country": "Angola",
    "CountryCode": "AO",
    "Slug": "angola",
    "NewConfirmed": 1360,
    "TotalConfirmed": 104491,
    "NewDeaths": 6,
    "TotalDeaths": 1923,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "38c12964-9e20-4488-9a5f-cd91df0b9ea3",
    "Country": "Antarctica",
    "CountryCode": "AQ",
    "Slug": "antarctica",
    "NewConfirmed": 0,
    "TotalConfirmed": 11,
    "NewDeaths": 0,
    "TotalDeaths": 0,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "5562d912-6d47-44c4-b738-8016cb166d63",
    "Country": "Antigua and Barbuda",
    "CountryCode": "AG",
    "Slug": "antigua-and-barbuda",
    "NewConfirmed": 0,
    "TotalConfirmed": 9106,
    "NewDeaths": 0,
    "TotalDeaths": 146,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "893d797c-698d-4814-b274-b7ee1c3f1635",
    "Country": "Argentina",
    "CountryCode": "AR",
    "Slug": "argentina",
    "NewConfirmed": 0,
    "TotalConfirmed": 9723924,
    "NewDeaths": 0,
    "TotalDeaths": 130017,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "340bd6d6-bcf7-4982-9b46-5f6594861f39",
    "Country": "Armenia",
    "CountryCode": "AM",
    "Slug": "armenia",
    "NewConfirmed": 0,
    "TotalConfirmed": 445631,
    "NewDeaths": 0,
    "TotalDeaths": 8709,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "04d4e8f0-c660-4675-b1ac-7951b1b0b768",
    "Country": "Australia",
    "CountryCode": "AU",
    "Slug": "australia",
    "NewConfirmed": 3840,
    "TotalConfirmed": 10571788,
    "NewDeaths": 12,
    "TotalDeaths": 16026,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "304b75a4-b75c-48b8-b14d-a91ef07b4fe1",
    "Country": "Austria",
    "CountryCode": "AT",
    "Slug": "austria",
    "NewConfirmed": 5773,
    "TotalConfirmed": 5530456,
    "NewDeaths": 10,
    "TotalDeaths": 21159,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "5d95ee2e-d645-41cd-af03-2b5bacc1ccf4",
    "Country": "Azerbaijan",
    "CountryCode": "AZ",
    "Slug": "azerbaijan",
    "NewConfirmed": 49,
    "TotalConfirmed": 824082,
    "NewDeaths": 0,
    "TotalDeaths": 9975,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "08e2d49d-0d67-45b6-91d2-dcd1474e84dc",
    "Country": "Bahamas",
    "CountryCode": "BS",
    "Slug": "bahamas",
    "NewConfirmed": 0,
    "TotalConfirmed": 37461,
    "NewDeaths": 0,
    "TotalDeaths": 833,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "1d772191-e496-430e-8f48-d47514f0d9ab",
    "Country": "Bahrain",
    "CountryCode": "BH",
    "Slug": "bahrain",
    "NewConfirmed": 169,
    "TotalConfirmed": 695448,
    "NewDeaths": 0,
    "TotalDeaths": 1535,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "09b08293-1331-412b-b09e-6e7e1c0102cf",
    "Country": "Bangladesh",
    "CountryCode": "BD",
    "Slug": "bangladesh",
    "NewConfirmed": 33,
    "TotalConfirmed": 2036449,
    "NewDeaths": 0,
    "TotalDeaths": 29431,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "78c4d2b9-bcb0-46b9-8ce9-f9f6d4d1181e",
    "Country": "Barbados",
    "CountryCode": "BB",
    "Slug": "barbados",
    "NewConfirmed": 410,
    "TotalConfirmed": 103955,
    "NewDeaths": 0,
    "TotalDeaths": 564,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "504e16f1-689e-4456-89f4-94cf3ee649a1",
    "Country": "Belarus",
    "CountryCode": "BY",
    "Slug": "belarus",
    "NewConfirmed": 0,
    "TotalConfirmed": 994037,
    "NewDeaths": 0,
    "TotalDeaths": 7118,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "240f2287-bb72-4bd5-8e1c-be9a7b7809d0",
    "Country": "Belgium",
    "CountryCode": "BE",
    "Slug": "belgium",
    "NewConfirmed": 0,
    "TotalConfirmed": 4629552,
    "NewDeaths": 0,
    "TotalDeaths": 33027,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "be986ed8-1208-4808-9395-b5d628fa4375",
    "Country": "Belize",
    "CountryCode": "BZ",
    "Slug": "belize",
    "NewConfirmed": 0,
    "TotalConfirmed": 69012,
    "NewDeaths": 0,
    "TotalDeaths": 688,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "ab8c0520-dc87-4fc1-bed8-0de852b543ad",
    "Country": "Benin",
    "CountryCode": "BJ",
    "Slug": "benin",
    "NewConfirmed": 0,
    "TotalConfirmed": 27922,
    "NewDeaths": 0,
    "TotalDeaths": 163,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "0c6fc398-d5e2-4cd9-b407-6fdf72fd74ea",
    "Country": "Bhutan",
    "CountryCode": "BT",
    "Slug": "bhutan",
    "NewConfirmed": 0,
    "TotalConfirmed": 62488,
    "NewDeaths": 0,
    "TotalDeaths": 21,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "4ccc4477-780c-46ff-8c12-54efdb430af0",
    "Country": "Bolivia",
    "CountryCode": "BO",
    "Slug": "bolivia",
    "NewConfirmed": 157,
    "TotalConfirmed": 1110413,
    "NewDeaths": 0,
    "TotalDeaths": 22244,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "2d58e0f1-b565-4921-871f-970f0ac29290",
    "Country": "Bosnia and Herzegovina",
    "CountryCode": "BA",
    "Slug": "bosnia-and-herzegovina",
    "NewConfirmed": 18,
    "TotalConfirmed": 400430,
    "NewDeaths": 1,
    "TotalDeaths": 16198,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "5f5b7ecc-9593-4708-9ccd-5cb61bd2c563",
    "Country": "Botswana",
    "CountryCode": "BW",
    "Slug": "botswana",
    "NewConfirmed": 0,
    "TotalConfirmed": 326344,
    "NewDeaths": 0,
    "TotalDeaths": 2790,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "43fda5b1-b756-47cd-9c82-f13d92946379",
    "Country": "Brazil",
    "CountryCode": "BR",
    "Slug": "brazil",
    "NewConfirmed": 29884,
    "TotalConfirmed": 35082036,
    "NewDeaths": 117,
    "TotalDeaths": 689272,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "a8f6a8bb-b986-4c97-9f40-26521b98d11f",
    "Country": "Brunei Darussalam",
    "CountryCode": "BN",
    "Slug": "brunei",
    "NewConfirmed": 4545,
    "TotalConfirmed": 253406,
    "NewDeaths": 0,
    "TotalDeaths": 225,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "31dc4f81-6ffb-4e9c-aa48-2a3051ff892a",
    "Country": "Bulgaria",
    "CountryCode": "BG",
    "Slug": "bulgaria",
    "NewConfirmed": 252,
    "TotalConfirmed": 1286107,
    "NewDeaths": 5,
    "TotalDeaths": 38020,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "ddc6fb23-4544-4d40-98af-02ea6625d1b2",
    "Country": "Burkina Faso",
    "CountryCode": "BF",
    "Slug": "burkina-faso",
    "NewConfirmed": 0,
    "TotalConfirmed": 21631,
    "NewDeaths": 0,
    "TotalDeaths": 387,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "28d64bc6-0026-40fb-b7f5-4cb60628d9bb",
    "Country": "Burundi",
    "CountryCode": "BI",
    "Slug": "burundi",
    "NewConfirmed": 139,
    "TotalConfirmed": 50778,
    "NewDeaths": 0,
    "TotalDeaths": 38,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "a51015a5-1cf5-4165-b4b1-668162cae9ef",
    "Country": "Cambodia",
    "CountryCode": "KH",
    "Slug": "cambodia",
    "NewConfirmed": 4,
    "TotalConfirmed": 138039,
    "NewDeaths": 0,
    "TotalDeaths": 3056,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "94062409-6d5d-4946-9528-7656d279696c",
    "Country": "Cameroon",
    "CountryCode": "CM",
    "Slug": "cameroon",
    "NewConfirmed": 0,
    "TotalConfirmed": 123993,
    "NewDeaths": 0,
    "TotalDeaths": 1965,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "8e43c933-1224-44b1-9fb2-0ba36a1fcc82",
    "Country": "Canada",
    "CountryCode": "CA",
    "Slug": "canada",
    "NewConfirmed": 1332,
    "TotalConfirmed": 3807514,
    "NewDeaths": 2,
    "TotalDeaths": 42605,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "f4c8c6c2-bc8b-40ef-9b70-70f0f92e319b",
    "Country": "Cape Verde",
    "CountryCode": "CV",
    "Slug": "cape-verde",
    "NewConfirmed": 0,
    "TotalConfirmed": 62862,
    "NewDeaths": 1,
    "TotalDeaths": 412,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "5950461b-b358-447d-b7f3-0b088fe71d7d",
    "Country": "Central African Republic",
    "CountryCode": "CF",
    "Slug": "central-african-republic",
    "NewConfirmed": 0,
    "TotalConfirmed": 15311,
    "NewDeaths": 0,
    "TotalDeaths": 113,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "21aafbd7-c66f-46cd-a8cb-2d6be9d002bd",
    "Country": "Chad",
    "CountryCode": "TD",
    "Slug": "chad",
    "NewConfirmed": 0,
    "TotalConfirmed": 7641,
    "NewDeaths": 0,
    "TotalDeaths": 194,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "df8133a9-50f8-46f6-a718-1c2f55682440",
    "Country": "Chile",
    "CountryCode": "CL",
    "Slug": "chile",
    "NewConfirmed": 5604,
    "TotalConfirmed": 4889186,
    "NewDeaths": 14,
    "TotalDeaths": 62231,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "ae4f7274-06b1-4e96-93da-32903fb8c840",
    "Country": "China",
    "CountryCode": "CN",
    "Slug": "china",
    "NewConfirmed": 11924,
    "TotalConfirmed": 2356291,
    "NewDeaths": 12,
    "TotalDeaths": 15872,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "f8ab58a1-abd9-478d-9afe-908423201bf7",
    "Country": "Colombia",
    "CountryCode": "CO",
    "Slug": "colombia",
    "NewConfirmed": 0,
    "TotalConfirmed": 6312657,
    "NewDeaths": 0,
    "TotalDeaths": 141881,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "974fe4a0-b94a-43c4-ba2e-487be7f7715e",
    "Country": "Comoros",
    "CountryCode": "KM",
    "Slug": "comoros",
    "NewConfirmed": 0,
    "TotalConfirmed": 8941,
    "NewDeaths": 0,
    "TotalDeaths": 161,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "ad6feed3-c804-437f-b64e-c3dc9c0e6217",
    "Country": "Congo (Brazzaville)",
    "CountryCode": "CG",
    "Slug": "congo-brazzaville",
    "NewConfirmed": 0,
    "TotalConfirmed": 25375,
    "NewDeaths": 0,
    "TotalDeaths": 386,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "d94c7fc5-853a-4f94-b7a8-1399dabd4447",
    "Country": "Congo (Kinshasa)",
    "CountryCode": "CD",
    "Slug": "congo-kinshasa",
    "NewConfirmed": 0,
    "TotalConfirmed": 93837,
    "NewDeaths": 0,
    "TotalDeaths": 1452,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "f8b1e433-7264-459f-b4ad-c93e1c2e4e43",
    "Country": "Costa Rica",
    "CountryCode": "CR",
    "Slug": "costa-rica",
    "NewConfirmed": 0,
    "TotalConfirmed": 1143597,
    "NewDeaths": 0,
    "TotalDeaths": 9031,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "c513fd7c-2c77-40f1-853f-2a2bc6a37301",
    "Country": "Croatia",
    "CountryCode": "HR",
    "Slug": "croatia",
    "NewConfirmed": 381,
    "TotalConfirmed": 1252036,
    "NewDeaths": 10,
    "TotalDeaths": 17278,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "27995377-59ce-49c9-8078-eece3281c6b2",
    "Country": "Cuba",
    "CountryCode": "CU",
    "Slug": "cuba",
    "NewConfirmed": 9,
    "TotalConfirmed": 1111354,
    "NewDeaths": 0,
    "TotalDeaths": 8530,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "af7d9f7e-69ba-4ca5-8c01-291c923bfa87",
    "Country": "Cyprus",
    "CountryCode": "CY",
    "Slug": "cyprus",
    "NewConfirmed": 0,
    "TotalConfirmed": 610023,
    "NewDeaths": 0,
    "TotalDeaths": 1218,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "2bba69f9-63b8-4b5a-8e60-8edee06003f6",
    "Country": "Czech Republic",
    "CountryCode": "CZ",
    "Slug": "czech-republic",
    "NewConfirmed": 629,
    "TotalConfirmed": 4169493,
    "NewDeaths": 2,
    "TotalDeaths": 41774,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "eded751c-d850-467a-ad88-9dba87ae14c5",
    "Country": "Côte d'Ivoire",
    "CountryCode": "CI",
    "Slug": "cote-divoire",
    "NewConfirmed": 1,
    "TotalConfirmed": 87882,
    "NewDeaths": 0,
    "TotalDeaths": 830,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "95278d53-eedb-4f70-bae9-3350b261631a",
    "Country": "Denmark",
    "CountryCode": "DK",
    "Slug": "denmark",
    "NewConfirmed": 676,
    "TotalConfirmed": 3394887,
    "NewDeaths": 4,
    "TotalDeaths": 7549,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "563e43e7-c528-4e6b-ab36-419548100ef0",
    "Country": "Djibouti",
    "CountryCode": "DJ",
    "Slug": "djibouti",
    "NewConfirmed": 0,
    "TotalConfirmed": 15690,
    "NewDeaths": 0,
    "TotalDeaths": 189,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "0bf4e47c-2f66-4265-aa5c-61aa69d883c4",
    "Country": "Dominica",
    "CountryCode": "DM",
    "Slug": "dominica",
    "NewConfirmed": 0,
    "TotalConfirmed": 15760,
    "NewDeaths": 0,
    "TotalDeaths": 74,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "3999c247-763f-438b-93c5-30ca26250a26",
    "Country": "Dominican Republic",
    "CountryCode": "DO",
    "Slug": "dominican-republic",
    "NewConfirmed": 0,
    "TotalConfirmed": 648456,
    "NewDeaths": 0,
    "TotalDeaths": 4384,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "a3929640-9b5e-4120-933f-91cbc5fe430f",
    "Country": "Ecuador",
    "CountryCode": "EC",
    "Slug": "ecuador",
    "NewConfirmed": 0,
    "TotalConfirmed": 1009958,
    "NewDeaths": 0,
    "TotalDeaths": 35940,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "a09f1020-a87b-4c57-aae6-81a662be7375",
    "Country": "Egypt",
    "CountryCode": "EG",
    "Slug": "egypt",
    "NewConfirmed": 0,
    "TotalConfirmed": 515645,
    "NewDeaths": 0,
    "TotalDeaths": 24798,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "689eeb2e-b64a-40ac-8c94-b17fc18cbd9d",
    "Country": "El Salvador",
    "CountryCode": "SV",
    "Slug": "el-salvador",
    "NewConfirmed": 0,
    "TotalConfirmed": 201785,
    "NewDeaths": 0,
    "TotalDeaths": 4230,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "0736e33a-c671-4e49-b381-2885b6c9885d",
    "Country": "Equatorial Guinea",
    "CountryCode": "GQ",
    "Slug": "equatorial-guinea",
    "NewConfirmed": 0,
    "TotalConfirmed": 17182,
    "NewDeaths": 0,
    "TotalDeaths": 183,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "062e9d24-b6a0-483d-806c-aa9d4678171a",
    "Country": "Eritrea",
    "CountryCode": "ER",
    "Slug": "eritrea",
    "NewConfirmed": 0,
    "TotalConfirmed": 10189,
    "NewDeaths": 0,
    "TotalDeaths": 103,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "85401f4f-4744-479d-b5d1-b5e08c7468f3",
    "Country": "Estonia",
    "CountryCode": "EE",
    "Slug": "estonia",
    "NewConfirmed": 0,
    "TotalConfirmed": 608796,
    "NewDeaths": 0,
    "TotalDeaths": 2757,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "3e1653af-aa0c-45c1-a342-f5f4b0b86436",
    "Country": "Ethiopia",
    "CountryCode": "ET",
    "Slug": "ethiopia",
    "NewConfirmed": 23,
    "TotalConfirmed": 494372,
    "NewDeaths": 0,
    "TotalDeaths": 7572,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "955bb17e-75cc-4be1-9bbb-0e4f00d0ccc7",
    "Country": "Fiji",
    "CountryCode": "FJ",
    "Slug": "fiji",
    "NewConfirmed": 0,
    "TotalConfirmed": 68307,
    "NewDeaths": 0,
    "TotalDeaths": 878,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "460a8ffd-992a-4d5c-9257-c93170cc35c2",
    "Country": "Finland",
    "CountryCode": "FI",
    "Slug": "finland",
    "NewConfirmed": 0,
    "TotalConfirmed": 1384654,
    "NewDeaths": 0,
    "TotalDeaths": 7173,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "52bf4bdd-a056-4e9d-958e-0dee574aa0ea",
    "Country": "France",
    "CountryCode": "FR",
    "Slug": "france",
    "NewConfirmed": 49339,
    "TotalConfirmed": 37690658,
    "NewDeaths": 84,
    "TotalDeaths": 159551,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "64378df3-9ed7-49e8-97a2-e4c390500747",
    "Country": "Gabon",
    "CountryCode": "GA",
    "Slug": "gabon",
    "NewConfirmed": 0,
    "TotalConfirmed": 48972,
    "NewDeaths": 0,
    "TotalDeaths": 306,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "8b70504d-3c02-46c5-8cf3-95b77361e58c",
    "Country": "Gambia",
    "CountryCode": "GM",
    "Slug": "gambia",
    "NewConfirmed": 0,
    "TotalConfirmed": 12586,
    "NewDeaths": 0,
    "TotalDeaths": 372,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "f9f7290d-24d3-43de-b447-6f7ceddafccc",
    "Country": "Georgia",
    "CountryCode": "GE",
    "Slug": "georgia",
    "NewConfirmed": 20560,
    "TotalConfirmed": 1805698,
    "NewDeaths": 0,
    "TotalDeaths": 16912,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "dac1d0bf-175e-497a-80e1-56741556e835",
    "Country": "Germany",
    "CountryCode": "DE",
    "Slug": "germany",
    "NewConfirmed": 38090,
    "TotalConfirmed": 36318461,
    "NewDeaths": 163,
    "TotalDeaths": 157114,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "31adb01d-8e78-412a-a378-f5496b6af996",
    "Country": "Ghana",
    "CountryCode": "GH",
    "Slug": "ghana",
    "NewConfirmed": 0,
    "TotalConfirmed": 170996,
    "NewDeaths": 0,
    "TotalDeaths": 1461,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "658d7670-e041-4fa3-9740-1f58af156823",
    "Country": "Greece",
    "CountryCode": "GR",
    "Slug": "greece",
    "NewConfirmed": 0,
    "TotalConfirmed": 5360506,
    "NewDeaths": 0,
    "TotalDeaths": 34178,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "5a8938f1-7b1a-4b0a-864c-ff6df584ba5b",
    "Country": "Grenada",
    "CountryCode": "GD",
    "Slug": "grenada",
    "NewConfirmed": 0,
    "TotalConfirmed": 19616,
    "NewDeaths": 0,
    "TotalDeaths": 237,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "eeade31b-55dd-4343-9d71-6e8de1e9a894",
    "Country": "Guatemala",
    "CountryCode": "GT",
    "Slug": "guatemala",
    "NewConfirmed": 941,
    "TotalConfirmed": 1150400,
    "NewDeaths": 2,
    "TotalDeaths": 19942,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "ca7a9525-e675-42ba-b716-27cc03d13b58",
    "Country": "Guinea",
    "CountryCode": "GN",
    "Slug": "guinea",
    "NewConfirmed": 0,
    "TotalConfirmed": 38153,
    "NewDeaths": 0,
    "TotalDeaths": 464,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "54236906-4174-498f-a5a9-34f9eeb05f3e",
    "Country": "Guinea-Bissau",
    "CountryCode": "GW",
    "Slug": "guinea-bissau",
    "NewConfirmed": 0,
    "TotalConfirmed": 8848,
    "NewDeaths": 0,
    "TotalDeaths": 176,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "da94f507-4d61-49cc-84da-d9d01b7ac97d",
    "Country": "Guyana",
    "CountryCode": "GY",
    "Slug": "guyana",
    "NewConfirmed": 10,
    "TotalConfirmed": 71558,
    "NewDeaths": 1,
    "TotalDeaths": 1284,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "eb3426bb-573c-4005-9ea8-8eebf8d68dc7",
    "Country": "Haiti",
    "CountryCode": "HT",
    "Slug": "haiti",
    "NewConfirmed": 0,
    "TotalConfirmed": 33837,
    "NewDeaths": 0,
    "TotalDeaths": 860,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "26375b84-ad20-4134-9991-001238447c96",
    "Country": "Holy See (Vatican City State)",
    "CountryCode": "VA",
    "Slug": "holy-see-vatican-city-state",
    "NewConfirmed": 0,
    "TotalConfirmed": 29,
    "NewDeaths": 0,
    "TotalDeaths": 0,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "addafb88-f632-4b7a-b6dc-01c29d34ceca",
    "Country": "Honduras",
    "CountryCode": "HN",
    "Slug": "honduras",
    "NewConfirmed": 0,
    "TotalConfirmed": 457676,
    "NewDeaths": 0,
    "TotalDeaths": 11043,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "d02db8e4-33c9-4db3-a73f-98c4dbd044b9",
    "Country": "Hungary",
    "CountryCode": "HU",
    "Slug": "hungary",
    "NewConfirmed": 4098,
    "TotalConfirmed": 2162093,
    "NewDeaths": 61,
    "TotalDeaths": 48245,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "0ab8a3c5-6163-49fa-aa0a-1407b494b96a",
    "Country": "Iceland",
    "CountryCode": "IS",
    "Slug": "iceland",
    "NewConfirmed": 0,
    "TotalConfirmed": 206803,
    "NewDeaths": 0,
    "TotalDeaths": 219,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "b8d72090-a885-453e-8390-4dd47570453d",
    "Country": "India",
    "CountryCode": "IN",
    "Slug": "india",
    "NewConfirmed": 408,
    "TotalConfirmed": 44671708,
    "NewDeaths": 5,
    "TotalDeaths": 530601,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "0a0d8f2f-cfe5-4bac-9e28-a48f9e270b5c",
    "Country": "Indonesia",
    "CountryCode": "ID",
    "Slug": "indonesia",
    "NewConfirmed": 7221,
    "TotalConfirmed": 6627538,
    "NewDeaths": 51,
    "TotalDeaths": 159524,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "9624136e-0ee6-4b5f-98ed-4c24981beb59",
    "Country": "Iran, Islamic Republic of",
    "CountryCode": "IR",
    "Slug": "iran",
    "NewConfirmed": 30,
    "TotalConfirmed": 7559446,
    "NewDeaths": 2,
    "TotalDeaths": 144632,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "ec8731bc-f2de-4914-bbbd-73e4833cc0cd",
    "Country": "Iraq",
    "CountryCode": "IQ",
    "Slug": "iraq",
    "NewConfirmed": 0,
    "TotalConfirmed": 2463021,
    "NewDeaths": 0,
    "TotalDeaths": 25363,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "5a73595a-11fc-4721-b7cd-9e9ac146fa62",
    "Country": "Ireland",
    "CountryCode": "IE",
    "Slug": "ireland",
    "NewConfirmed": 1441,
    "TotalConfirmed": 1678827,
    "NewDeaths": 29,
    "TotalDeaths": 8170,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "6f4d0ed3-284c-483b-9628-1e53346ee783",
    "Country": "Israel",
    "CountryCode": "IL",
    "Slug": "israel",
    "NewConfirmed": 0,
    "TotalConfirmed": 4709796,
    "NewDeaths": 0,
    "TotalDeaths": 11823,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "d1f8d790-e374-4266-8eec-85186de75a50",
    "Country": "Italy",
    "CountryCode": "IT",
    "Slug": "italy",
    "NewConfirmed": 0,
    "TotalConfirmed": 24031538,
    "NewDeaths": 0,
    "TotalDeaths": 180518,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "52b2b9e3-4a65-4f85-9787-e06050a4e79e",
    "Country": "Jamaica",
    "CountryCode": "JM",
    "Slug": "jamaica",
    "NewConfirmed": 0,
    "TotalConfirmed": 152517,
    "NewDeaths": 0,
    "TotalDeaths": 3399,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "2ddddc36-983b-413e-8637-681ed184de0e",
    "Country": "Japan",
    "CountryCode": "JP",
    "Slug": "japan",
    "NewConfirmed": 133361,
    "TotalConfirmed": 24090948,
    "NewDeaths": 160,
    "TotalDeaths": 48650,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "d7b4c6ce-1e8d-4820-8e8c-4f293d6c0bf8",
    "Country": "Jordan",
    "CountryCode": "JO",
    "Slug": "jordan",
    "NewConfirmed": 0,
    "TotalConfirmed": 1746997,
    "NewDeaths": 0,
    "TotalDeaths": 14122,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "e35eab85-ddb9-4f2f-8c63-ce8c7c9d5626",
    "Country": "Kazakhstan",
    "CountryCode": "KZ",
    "Slug": "kazakhstan",
    "NewConfirmed": 0,
    "TotalConfirmed": 1485635,
    "NewDeaths": 0,
    "TotalDeaths": 19053,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "029a7945-5edc-4e05-bfa9-7233e4c88642",
    "Country": "Kenya",
    "CountryCode": "KE",
    "Slug": "kenya",
    "NewConfirmed": 119,
    "TotalConfirmed": 341235,
    "NewDeaths": 0,
    "TotalDeaths": 5684,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "e7e268fa-cfa1-4384-bc50-285df81401ec",
    "Country": "Kiribati",
    "CountryCode": "KI",
    "Slug": "kiribati",
    "NewConfirmed": 0,
    "TotalConfirmed": 3430,
    "NewDeaths": 0,
    "TotalDeaths": 13,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "6aa9cf39-00d3-4576-956b-6ee32a130c33",
    "Country": "Korea (North)",
    "CountryCode": "KP",
    "Slug": "korea-north",
    "NewConfirmed": 0,
    "TotalConfirmed": 1,
    "NewDeaths": 0,
    "TotalDeaths": 6,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "0e56e20c-09ff-4ddb-92dc-5de630e82a39",
    "Country": "Korea (South)",
    "CountryCode": "KR",
    "Slug": "korea-south",
    "NewConfirmed": 59089,
    "TotalConfirmed": 26784142,
    "NewDeaths": 59,
    "TotalDeaths": 30223,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "17f07fff-4ba6-4155-91ef-8292529a45d0",
    "Country": "Kuwait",
    "CountryCode": "KW",
    "Slug": "kuwait",
    "NewConfirmed": 0,
    "TotalConfirmed": 662617,
    "NewDeaths": 0,
    "TotalDeaths": 2569,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "23a0d2f8-f857-4474-83f2-667a46672ecc",
    "Country": "Kyrgyzstan",
    "CountryCode": "KG",
    "Slug": "kyrgyzstan",
    "NewConfirmed": 0,
    "TotalConfirmed": 206511,
    "NewDeaths": 0,
    "TotalDeaths": 2991,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "1649ea08-77d0-4319-ab1a-ada7e7f747e6",
    "Country": "Lao PDR",
    "CountryCode": "LA",
    "Slug": "lao-pdr",
    "NewConfirmed": 25,
    "TotalConfirmed": 216554,
    "NewDeaths": 0,
    "TotalDeaths": 758,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "6931e6b5-ba88-4f99-b0ed-10d3f3f3ec17",
    "Country": "Latvia",
    "CountryCode": "LV",
    "Slug": "latvia",
    "NewConfirmed": 636,
    "TotalConfirmed": 961086,
    "NewDeaths": 1,
    "TotalDeaths": 6085,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "64d6e516-aff3-4841-9eed-6600fb89bbab",
    "Country": "Lebanon",
    "CountryCode": "LB",
    "Slug": "lebanon",
    "NewConfirmed": 54,
    "TotalConfirmed": 1220211,
    "NewDeaths": 1,
    "TotalDeaths": 10731,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "d9ee5c06-9fd1-49c5-9d59-513dcb4cd64d",
    "Country": "Lesotho",
    "CountryCode": "LS",
    "Slug": "lesotho",
    "NewConfirmed": 0,
    "TotalConfirmed": 34490,
    "NewDeaths": 0,
    "TotalDeaths": 706,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "49bc8cd3-d1f5-4168-ad7a-2690a78539b0",
    "Country": "Liberia",
    "CountryCode": "LR",
    "Slug": "liberia",
    "NewConfirmed": 0,
    "TotalConfirmed": 8014,
    "NewDeaths": 0,
    "TotalDeaths": 294,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "b5c58956-42f6-4df9-853a-30d196d10571",
    "Country": "Libya",
    "CountryCode": "LY",
    "Slug": "libya",
    "NewConfirmed": 0,
    "TotalConfirmed": 507078,
    "NewDeaths": 0,
    "TotalDeaths": 6437,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "56d2e2c3-56ab-4580-bcfa-d82e69b7da16",
    "Country": "Liechtenstein",
    "CountryCode": "LI",
    "Slug": "liechtenstein",
    "NewConfirmed": 28,
    "TotalConfirmed": 20859,
    "NewDeaths": 0,
    "TotalDeaths": 87,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "0a7741d2-220c-4091-b45b-7ca6753b02de",
    "Country": "Lithuania",
    "CountryCode": "LT",
    "Slug": "lithuania",
    "NewConfirmed": 332,
    "TotalConfirmed": 1273886,
    "NewDeaths": 2,
    "TotalDeaths": 9422,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "841f2e69-b768-4b5f-9c01-ee459c52d1a0",
    "Country": "Luxembourg",
    "CountryCode": "LU",
    "Slug": "luxembourg",
    "NewConfirmed": 260,
    "TotalConfirmed": 305694,
    "NewDeaths": 0,
    "TotalDeaths": 1157,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "e8686881-42d7-4909-90a8-5691c4337a74",
    "Country": "Macedonia, Republic of",
    "CountryCode": "MK",
    "Slug": "macedonia",
    "NewConfirmed": 179,
    "TotalConfirmed": 344710,
    "NewDeaths": 1,
    "TotalDeaths": 9568,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "4f155921-3f7a-4dfe-84f3-a8551e9673e5",
    "Country": "Madagascar",
    "CountryCode": "MG",
    "Slug": "madagascar",
    "NewConfirmed": 0,
    "TotalConfirmed": 67054,
    "NewDeaths": 0,
    "TotalDeaths": 1411,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "b6bbc2f4-2ff7-4518-a0fb-ca032f80e5af",
    "Country": "Malawi",
    "CountryCode": "MW",
    "Slug": "malawi",
    "NewConfirmed": 0,
    "TotalConfirmed": 88073,
    "NewDeaths": 0,
    "TotalDeaths": 2685,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "0fbb1be8-e402-4f76-933d-536a05dd88de",
    "Country": "Malaysia",
    "CountryCode": "MY",
    "Slug": "malaysia",
    "NewConfirmed": 3537,
    "TotalConfirmed": 4975473,
    "NewDeaths": 8,
    "TotalDeaths": 36628,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "7e2ec8b8-522b-418f-a749-87de57892abf",
    "Country": "Maldives",
    "CountryCode": "MV",
    "Slug": "maldives",
    "NewConfirmed": 0,
    "TotalConfirmed": 185584,
    "NewDeaths": 0,
    "TotalDeaths": 311,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "f7ff4cfd-bc5b-4165-b999-9fc7da9ff795",
    "Country": "Mali",
    "CountryCode": "ML",
    "Slug": "mali",
    "NewConfirmed": 0,
    "TotalConfirmed": 32756,
    "NewDeaths": 0,
    "TotalDeaths": 742,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "741f2404-9a46-4c6a-bd5a-a6824fc288fb",
    "Country": "Malta",
    "CountryCode": "MT",
    "Slug": "malta",
    "NewConfirmed": 13,
    "TotalConfirmed": 115734,
    "NewDeaths": 1,
    "TotalDeaths": 809,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "13b0aa82-2442-472b-8f00-b77a8174a249",
    "Country": "Marshall Islands",
    "CountryCode": "MH",
    "Slug": "marshall-islands",
    "NewConfirmed": 0,
    "TotalConfirmed": 15501,
    "NewDeaths": 0,
    "TotalDeaths": 17,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "81937115-2829-475c-84f5-fd7d5adda58d",
    "Country": "Mauritania",
    "CountryCode": "MR",
    "Slug": "mauritania",
    "NewConfirmed": 0,
    "TotalConfirmed": 63419,
    "NewDeaths": 0,
    "TotalDeaths": 997,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "f701983c-7044-4cf8-a853-43fe0b715de8",
    "Country": "Mauritius",
    "CountryCode": "MU",
    "Slug": "mauritius",
    "NewConfirmed": 3470,
    "TotalConfirmed": 271867,
    "NewDeaths": 1,
    "TotalDeaths": 1034,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "eef6bcc9-c1ad-446d-9a5d-698bc58fdfa5",
    "Country": "Mexico",
    "CountryCode": "MX",
    "Slug": "mexico",
    "NewConfirmed": 0,
    "TotalConfirmed": 7125443,
    "NewDeaths": 2,
    "TotalDeaths": 330495,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "c5ea3366-ec25-4814-9413-1f45a609330e",
    "Country": "Micronesia, Federated States of",
    "CountryCode": "FM",
    "Slug": "micronesia",
    "NewConfirmed": 0,
    "TotalConfirmed": 22203,
    "NewDeaths": 0,
    "TotalDeaths": 55,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "16fd4f95-6810-4c29-8301-ffe42e9dcf37",
    "Country": "Moldova",
    "CountryCode": "MD",
    "Slug": "moldova",
    "NewConfirmed": 0,
    "TotalConfirmed": 594754,
    "NewDeaths": 0,
    "TotalDeaths": 11913,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "70730ff6-f0ea-47cc-afd4-bb9685239df3",
    "Country": "Monaco",
    "CountryCode": "MC",
    "Slug": "monaco",
    "NewConfirmed": 0,
    "TotalConfirmed": 15261,
    "NewDeaths": 0,
    "TotalDeaths": 65,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "d8d0a7d1-d01d-4791-99ca-9d9a6f3c31b2",
    "Country": "Mongolia",
    "CountryCode": "MN",
    "Slug": "mongolia",
    "NewConfirmed": 263,
    "TotalConfirmed": 990994,
    "NewDeaths": 0,
    "TotalDeaths": 2134,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "4ffc1559-17cc-45f7-af33-a1f7c9506af1",
    "Country": "Montenegro",
    "CountryCode": "ME",
    "Slug": "montenegro",
    "NewConfirmed": 30,
    "TotalConfirmed": 283566,
    "NewDeaths": 0,
    "TotalDeaths": 2789,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "3de53bfc-e89a-406c-8c9f-77e84b982230",
    "Country": "Morocco",
    "CountryCode": "MA",
    "Slug": "morocco",
    "NewConfirmed": 219,
    "TotalConfirmed": 1267816,
    "NewDeaths": 0,
    "TotalDeaths": 16283,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "a7a54e11-e786-48d9-bb8b-e4cbe0b5276d",
    "Country": "Mozambique",
    "CountryCode": "MZ",
    "Slug": "mozambique",
    "NewConfirmed": 0,
    "TotalConfirmed": 230590,
    "NewDeaths": 0,
    "TotalDeaths": 2224,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "e0b15b99-daff-49bf-b730-22b2f926a554",
    "Country": "Myanmar",
    "CountryCode": "MM",
    "Slug": "myanmar",
    "NewConfirmed": 21,
    "TotalConfirmed": 633096,
    "NewDeaths": 1,
    "TotalDeaths": 19488,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "debc2af1-4902-47aa-8139-cf581a29c516",
    "Country": "Namibia",
    "CountryCode": "NA",
    "Slug": "namibia",
    "NewConfirmed": 0,
    "TotalConfirmed": 169946,
    "NewDeaths": 0,
    "TotalDeaths": 4080,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "7dd3e7f2-c4eb-4b7e-8e9f-8f3c7998826a",
    "Country": "Nauru",
    "CountryCode": "NR",
    "Slug": "nauru",
    "NewConfirmed": 0,
    "TotalConfirmed": 4621,
    "NewDeaths": 0,
    "TotalDeaths": 1,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "9577d0c7-d667-4c45-9df9-13e6be3fc2d7",
    "Country": "Nepal",
    "CountryCode": "NP",
    "Slug": "nepal",
    "NewConfirmed": 4,
    "TotalConfirmed": 1000863,
    "NewDeaths": 0,
    "TotalDeaths": 12019,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "3938a4cb-0e20-4272-a1cf-9da76554f6be",
    "Country": "Netherlands",
    "CountryCode": "NL",
    "Slug": "netherlands",
    "NewConfirmed": 0,
    "TotalConfirmed": 8536718,
    "NewDeaths": 0,
    "TotalDeaths": 22901,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "7c8f8733-1491-4b15-852f-4e55241ffb04",
    "Country": "New Zealand",
    "CountryCode": "NZ",
    "Slug": "new-zealand",
    "NewConfirmed": 2,
    "TotalConfirmed": 1924563,
    "NewDeaths": 0,
    "TotalDeaths": 2183,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "989a4e3d-2531-4031-8131-9911a7d5c650",
    "Country": "Nicaragua",
    "CountryCode": "NI",
    "Slug": "nicaragua",
    "NewConfirmed": 13,
    "TotalConfirmed": 15232,
    "NewDeaths": 0,
    "TotalDeaths": 245,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "c409b05b-10f1-4b68-9890-fe63fcd8241e",
    "Country": "Niger",
    "CountryCode": "NE",
    "Slug": "niger",
    "NewConfirmed": 0,
    "TotalConfirmed": 9430,
    "NewDeaths": 0,
    "TotalDeaths": 314,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "923c2c7c-2fdd-4b5e-afdc-c0e3664d5804",
    "Country": "Nigeria",
    "CountryCode": "NG",
    "Slug": "nigeria",
    "NewConfirmed": 0,
    "TotalConfirmed": 266283,
    "NewDeaths": 0,
    "TotalDeaths": 3155,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "4478598e-c8a4-4fca-8a47-fa9dd8485278",
    "Country": "Norway",
    "CountryCode": "NO",
    "Slug": "norway",
    "NewConfirmed": 170,
    "TotalConfirmed": 1467945,
    "NewDeaths": 0,
    "TotalDeaths": 4332,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "0468499c-a00e-4c6a-8f81-53af7b43c5e7",
    "Country": "Oman",
    "CountryCode": "OM",
    "Slug": "oman",
    "NewConfirmed": 0,
    "TotalConfirmed": 399027,
    "NewDeaths": 0,
    "TotalDeaths": 4628,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "a1f13fbb-e660-4ab1-895c-4f2932ef7e79",
    "Country": "Pakistan",
    "CountryCode": "PK",
    "Slug": "pakistan",
    "NewConfirmed": 27,
    "TotalConfirmed": 1574965,
    "NewDeaths": 0,
    "TotalDeaths": 30630,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "ac706a04-f464-4acc-a5f5-17afc9ba2f98",
    "Country": "Palau",
    "CountryCode": "PW",
    "Slug": "palau",
    "NewConfirmed": 0,
    "TotalConfirmed": 5684,
    "NewDeaths": 0,
    "TotalDeaths": 7,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "73033245-7084-4f67-99fa-7b75b7764646",
    "Country": "Palestinian Territory",
    "CountryCode": "PS",
    "Slug": "palestine",
    "NewConfirmed": 0,
    "TotalConfirmed": 703036,
    "NewDeaths": 0,
    "TotalDeaths": 5708,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "7886603b-c5e3-4142-92f7-277da3589dc8",
    "Country": "Panama",
    "CountryCode": "PA",
    "Slug": "panama",
    "NewConfirmed": 0,
    "TotalConfirmed": 995726,
    "NewDeaths": 0,
    "TotalDeaths": 8519,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "38882b3f-4f40-4799-9d14-36c5f98b5570",
    "Country": "Papua New Guinea",
    "CountryCode": "PG",
    "Slug": "papua-new-guinea",
    "NewConfirmed": 0,
    "TotalConfirmed": 45819,
    "NewDeaths": 0,
    "TotalDeaths": 668,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "eb139749-bdec-4244-967f-dbbf68547e37",
    "Country": "Paraguay",
    "CountryCode": "PY",
    "Slug": "paraguay",
    "NewConfirmed": 0,
    "TotalConfirmed": 718071,
    "NewDeaths": 0,
    "TotalDeaths": 19611,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "bd5629be-315b-487b-b793-269c159269f2",
    "Country": "Peru",
    "CountryCode": "PE",
    "Slug": "peru",
    "NewConfirmed": 0,
    "TotalConfirmed": 4197026,
    "NewDeaths": 0,
    "TotalDeaths": 217287,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "00eda542-3d19-44fd-89e3-2da76f6042e2",
    "Country": "Philippines",
    "CountryCode": "PH",
    "Slug": "philippines",
    "NewConfirmed": 718,
    "TotalConfirmed": 4028187,
    "NewDeaths": 4,
    "TotalDeaths": 64504,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "0d27e5f3-5cb8-4598-b567-5c87eef34714",
    "Country": "Poland",
    "CountryCode": "PL",
    "Slug": "poland",
    "NewConfirmed": 448,
    "TotalConfirmed": 6349973,
    "NewDeaths": 10,
    "TotalDeaths": 118292,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "ec368280-a0f9-4cda-a2f6-3f6f7236e341",
    "Country": "Portugal",
    "CountryCode": "PT",
    "Slug": "portugal",
    "NewConfirmed": 0,
    "TotalConfirmed": 5537108,
    "NewDeaths": 0,
    "TotalDeaths": 25395,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "4a9a8d19-ce10-4eb4-912f-ede496b79dc2",
    "Country": "Qatar",
    "CountryCode": "QA",
    "Slug": "qatar",
    "NewConfirmed": 229,
    "TotalConfirmed": 476683,
    "NewDeaths": 0,
    "TotalDeaths": 684,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "20193372-638b-4fda-bce7-64b999d1caed",
    "Country": "Republic of Kosovo",
    "CountryCode": "XK",
    "Slug": "kosovo",
    "NewConfirmed": 5,
    "TotalConfirmed": 272203,
    "NewDeaths": 0,
    "TotalDeaths": 3202,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "f3ebc4d3-ea2b-4ac9-b6a2-b50cbf7a45c3",
    "Country": "Romania",
    "CountryCode": "RO",
    "Slug": "romania",
    "NewConfirmed": 0,
    "TotalConfirmed": 3294447,
    "NewDeaths": 0,
    "TotalDeaths": 67253,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "6d898d06-f47b-4f5f-8c00-dc5a05bead03",
    "Country": "Russian Federation",
    "CountryCode": "RU",
    "Slug": "russia",
    "NewConfirmed": 5591,
    "TotalConfirmed": 21244991,
    "NewDeaths": 52,
    "TotalDeaths": 383728,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "d96cc9e8-9676-4332-a213-50ee9a4464c0",
    "Country": "Rwanda",
    "CountryCode": "RW",
    "Slug": "rwanda",
    "NewConfirmed": 38,
    "TotalConfirmed": 132681,
    "NewDeaths": 0,
    "TotalDeaths": 1467,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "b1cb2019-d78a-422e-acc0-f17716dc9796",
    "Country": "Saint Kitts and Nevis",
    "CountryCode": "KN",
    "Slug": "saint-kitts-and-nevis",
    "NewConfirmed": 2,
    "TotalConfirmed": 6554,
    "NewDeaths": 0,
    "TotalDeaths": 46,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "ac25f20b-1da2-4f78-80aa-eb005cfa7972",
    "Country": "Saint Lucia",
    "CountryCode": "LC",
    "Slug": "saint-lucia",
    "NewConfirmed": 0,
    "TotalConfirmed": 29692,
    "NewDeaths": 0,
    "TotalDeaths": 408,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "db1afc9e-f612-47f3-982e-160f28da954c",
    "Country": "Saint Vincent and Grenadines",
    "CountryCode": "VC",
    "Slug": "saint-vincent-and-the-grenadines",
    "NewConfirmed": 0,
    "TotalConfirmed": 9462,
    "NewDeaths": 0,
    "TotalDeaths": 116,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "618874af-105f-4f56-96a7-df02744a1b13",
    "Country": "Samoa",
    "CountryCode": "WS",
    "Slug": "samoa",
    "NewConfirmed": 0,
    "TotalConfirmed": 15964,
    "NewDeaths": 0,
    "TotalDeaths": 29,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "ddc4ad1b-2986-4069-9e4f-6cd89e368aff",
    "Country": "San Marino",
    "CountryCode": "SM",
    "Slug": "san-marino",
    "NewConfirmed": 0,
    "TotalConfirmed": 21798,
    "NewDeaths": 0,
    "TotalDeaths": 119,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "a547ee53-307c-410e-b6b5-af0f237a59d3",
    "Country": "Sao Tome and Principe",
    "CountryCode": "ST",
    "Slug": "sao-tome-and-principe",
    "NewConfirmed": 0,
    "TotalConfirmed": 6278,
    "NewDeaths": 0,
    "TotalDeaths": 77,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "b441bc3b-0965-4605-bd43-f7dc5f17ab05",
    "Country": "Saudi Arabia",
    "CountryCode": "SA",
    "Slug": "saudi-arabia",
    "NewConfirmed": 51,
    "TotalConfirmed": 825290,
    "NewDeaths": 2,
    "TotalDeaths": 9451,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "0d93f9ed-5078-468d-bcf7-76f11b61172a",
    "Country": "Senegal",
    "CountryCode": "SN",
    "Slug": "senegal",
    "NewConfirmed": 0,
    "TotalConfirmed": 88859,
    "NewDeaths": 0,
    "TotalDeaths": 1968,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "6e1196cc-13bd-4f8e-bd68-c2962981c304",
    "Country": "Serbia",
    "CountryCode": "RS",
    "Slug": "serbia",
    "NewConfirmed": 645,
    "TotalConfirmed": 2419618,
    "NewDeaths": 2,
    "TotalDeaths": 17352,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "f3b6bd8a-4de7-4297-9f3a-42fc61c45a3c",
    "Country": "Seychelles",
    "CountryCode": "SC",
    "Slug": "seychelles",
    "NewConfirmed": 482,
    "TotalConfirmed": 49862,
    "NewDeaths": 127,
    "TotalDeaths": 298,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "6240c62e-641d-42f3-a98a-11a8f42a1193",
    "Country": "Sierra Leone",
    "CountryCode": "SL",
    "Slug": "sierra-leone",
    "NewConfirmed": 0,
    "TotalConfirmed": 7758,
    "NewDeaths": 0,
    "TotalDeaths": 126,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "ea8f752e-d1c4-4bea-bcc8-8c5b8613602a",
    "Country": "Singapore",
    "CountryCode": "SG",
    "Slug": "singapore",
    "NewConfirmed": 1688,
    "TotalConfirmed": 2158709,
    "NewDeaths": 0,
    "TotalDeaths": 1701,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "8a130a39-1d74-4056-a96e-94170fa4daf3",
    "Country": "Slovakia",
    "CountryCode": "SK",
    "Slug": "slovakia",
    "NewConfirmed": 224,
    "TotalConfirmed": 2648680,
    "NewDeaths": 4,
    "TotalDeaths": 20719,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "3ba4dc59-822b-4b21-bb5f-869c69227ac7",
    "Country": "Slovenia",
    "CountryCode": "SI",
    "Slug": "slovenia",
    "NewConfirmed": 1159,
    "TotalConfirmed": 1250731,
    "NewDeaths": 2,
    "TotalDeaths": 6917,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "1b82d588-94af-454a-bea1-8f174ee9746a",
    "Country": "Solomon Islands",
    "CountryCode": "SB",
    "Slug": "solomon-islands",
    "NewConfirmed": 0,
    "TotalConfirmed": 24575,
    "NewDeaths": 0,
    "TotalDeaths": 153,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "a60a3b7b-97cb-49e5-a6f4-ceb2bf043c72",
    "Country": "Somalia",
    "CountryCode": "SO",
    "Slug": "somalia",
    "NewConfirmed": 0,
    "TotalConfirmed": 27254,
    "NewDeaths": 0,
    "TotalDeaths": 1361,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "3a836f06-254c-47cc-b17c-6dd1b232b07a",
    "Country": "South Africa",
    "CountryCode": "ZA",
    "Slug": "south-africa",
    "NewConfirmed": 843,
    "TotalConfirmed": 4039344,
    "NewDeaths": 0,
    "TotalDeaths": 102395,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "4960814a-f42f-4904-8623-8837b332cd41",
    "Country": "South Sudan",
    "CountryCode": "SS",
    "Slug": "south-sudan",
    "NewConfirmed": 0,
    "TotalConfirmed": 18348,
    "NewDeaths": 0,
    "TotalDeaths": 138,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "1f15a332-02a3-4907-ab5b-6f1c6fdc6d1e",
    "Country": "Spain",
    "CountryCode": "ES",
    "Slug": "spain",
    "NewConfirmed": 0,
    "TotalConfirmed": 13573721,
    "NewDeaths": 0,
    "TotalDeaths": 115641,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "8345ab28-7585-4c3a-b38c-d7486a6530ef",
    "Country": "Sri Lanka",
    "CountryCode": "LK",
    "Slug": "sri-lanka",
    "NewConfirmed": 23,
    "TotalConfirmed": 671586,
    "NewDeaths": 1,
    "TotalDeaths": 16796,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "f21d833f-8428-4efc-ba75-66f1ff5eeecd",
    "Country": "Sudan",
    "CountryCode": "SD",
    "Slug": "sudan",
    "NewConfirmed": 0,
    "TotalConfirmed": 63625,
    "NewDeaths": 0,
    "TotalDeaths": 4986,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "0cf47a32-c386-4c67-9a85-f78e327049e8",
    "Country": "Suriname",
    "CountryCode": "SR",
    "Slug": "suriname",
    "NewConfirmed": 0,
    "TotalConfirmed": 81228,
    "NewDeaths": 0,
    "TotalDeaths": 1392,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "066e9939-571d-4c78-bfff-cfdc4a8471d4",
    "Country": "Swaziland",
    "CountryCode": "SZ",
    "Slug": "swaziland",
    "NewConfirmed": 0,
    "TotalConfirmed": 73770,
    "NewDeaths": 0,
    "TotalDeaths": 1422,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "e14bb590-82e1-4d76-9997-132d86cd07b1",
    "Country": "Sweden",
    "CountryCode": "SE",
    "Slug": "sweden",
    "NewConfirmed": 0,
    "TotalConfirmed": 2622790,
    "NewDeaths": 0,
    "TotalDeaths": 20928,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "fcc3880e-4c13-4c0d-b25d-9210ecb737f7",
    "Country": "Switzerland",
    "CountryCode": "CH",
    "Slug": "switzerland",
    "NewConfirmed": 0,
    "TotalConfirmed": 4318881,
    "NewDeaths": 2,
    "TotalDeaths": 14116,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "01df88a2-07fc-493c-83d4-da458ac21954",
    "Country": "Syrian Arab Republic (Syria)",
    "CountryCode": "SY",
    "Slug": "syria",
    "NewConfirmed": 1,
    "TotalConfirmed": 57391,
    "NewDeaths": 0,
    "TotalDeaths": 3163,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "a9d1d145-4686-4c98-9de2-bffaefd66ce0",
    "Country": "Taiwan, Republic of China",
    "CountryCode": "TW",
    "Slug": "taiwan",
    "NewConfirmed": 17883,
    "TotalConfirmed": 8210955,
    "NewDeaths": 31,
    "TotalDeaths": 14060,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "26f05e59-738c-42a4-bd1c-32c37393562b",
    "Country": "Tajikistan",
    "CountryCode": "TJ",
    "Slug": "tajikistan",
    "NewConfirmed": 0,
    "TotalConfirmed": 17786,
    "NewDeaths": 0,
    "TotalDeaths": 125,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "fd892d9d-580c-4391-81ed-4199ffd48553",
    "Country": "Tanzania, United Republic of",
    "CountryCode": "TZ",
    "Slug": "tanzania",
    "NewConfirmed": 0,
    "TotalConfirmed": 40471,
    "NewDeaths": 0,
    "TotalDeaths": 845,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "e657250f-243a-4fb9-81c7-443abdd96251",
    "Country": "Thailand",
    "CountryCode": "TH",
    "Slug": "thailand",
    "NewConfirmed": 0,
    "TotalConfirmed": 4702330,
    "NewDeaths": 0,
    "TotalDeaths": 33106,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "8b94fcfa-a472-438b-b851-5fce48894b3c",
    "Country": "Timor-Leste",
    "CountryCode": "TL",
    "Slug": "timor-leste",
    "NewConfirmed": 8,
    "TotalConfirmed": 23335,
    "NewDeaths": 0,
    "TotalDeaths": 138,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "24180acc-46a7-42c5-8ac9-a586035618c7",
    "Country": "Togo",
    "CountryCode": "TG",
    "Slug": "togo",
    "NewConfirmed": 0,
    "TotalConfirmed": 39323,
    "NewDeaths": 0,
    "TotalDeaths": 290,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "d30da5ef-6113-452a-b461-cd73eec27418",
    "Country": "Tonga",
    "CountryCode": "TO",
    "Slug": "tonga",
    "NewConfirmed": 0,
    "TotalConfirmed": 16182,
    "NewDeaths": 0,
    "TotalDeaths": 12,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "37aa7dbb-d718-465a-a354-9e87289635ae",
    "Country": "Trinidad and Tobago",
    "CountryCode": "TT",
    "Slug": "trinidad-and-tobago",
    "NewConfirmed": 0,
    "TotalConfirmed": 185237,
    "NewDeaths": 0,
    "TotalDeaths": 4266,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "87f62b20-3e33-444b-b97f-7f5978bd1064",
    "Country": "Tunisia",
    "CountryCode": "TN",
    "Slug": "tunisia",
    "NewConfirmed": 0,
    "TotalConfirmed": 1146991,
    "NewDeaths": 0,
    "TotalDeaths": 29268,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "0664a70e-9744-4bef-8087-ddf4cb0b6970",
    "Country": "Turkey",
    "CountryCode": "TR",
    "Slug": "turkey",
    "NewConfirmed": 0,
    "TotalConfirmed": 16919638,
    "NewDeaths": 0,
    "TotalDeaths": 101203,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "66d0c22e-84c5-41d7-aef1-1dde71bc2d6c",
    "Country": "Tuvalu",
    "CountryCode": "TV",
    "Slug": "tuvalu",
    "NewConfirmed": 23,
    "TotalConfirmed": 2828,
    "NewDeaths": 0,
    "TotalDeaths": 0,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "9963373e-7004-4934-843c-9117f753c358",
    "Country": "Uganda",
    "CountryCode": "UG",
    "Slug": "uganda",
    "NewConfirmed": 0,
    "TotalConfirmed": 169663,
    "NewDeaths": 0,
    "TotalDeaths": 3630,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "1128447b-8e5a-48c1-a398-7ad1183ba37b",
    "Country": "Ukraine",
    "CountryCode": "UA",
    "Slug": "ukraine",
    "NewConfirmed": 6797,
    "TotalConfirmed": 5642284,
    "NewDeaths": 104,
    "TotalDeaths": 118399,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "d2f81ad4-172c-4c5c-9f76-49822aade15c",
    "Country": "United Arab Emirates",
    "CountryCode": "AE",
    "Slug": "united-arab-emirates",
    "NewConfirmed": 200,
    "TotalConfirmed": 1043166,
    "NewDeaths": 0,
    "TotalDeaths": 2348,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "41172b83-c51e-4444-bc7b-c2411f22e18f",
    "Country": "United Kingdom",
    "CountryCode": "GB",
    "Slug": "united-kingdom",
    "NewConfirmed": 100,
    "TotalConfirmed": 24203988,
    "NewDeaths": 0,
    "TotalDeaths": 212086,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "95d90b3d-325a-4796-88df-e43971ff040e",
    "Country": "United States of America",
    "CountryCode": "US",
    "Slug": "united-states",
    "NewConfirmed": 111386,
    "TotalConfirmed": 98503462,
    "NewDeaths": 1093,
    "TotalDeaths": 1078929,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "6da5a691-dcbe-4e8e-a20a-23d3790db5a9",
    "Country": "Uruguay",
    "CountryCode": "UY",
    "Slug": "uruguay",
    "NewConfirmed": 0,
    "TotalConfirmed": 992887,
    "NewDeaths": 0,
    "TotalDeaths": 7530,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "f051ac28-689a-422b-bb98-38a82ad0bda4",
    "Country": "Uzbekistan",
    "CountryCode": "UZ",
    "Slug": "uzbekistan",
    "NewConfirmed": 96,
    "TotalConfirmed": 245665,
    "NewDeaths": 0,
    "TotalDeaths": 1637,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "5ff69ba6-64a7-435d-869f-652a91474c62",
    "Country": "Vanuatu",
    "CountryCode": "VU",
    "Slug": "vanuatu",
    "NewConfirmed": 0,
    "TotalConfirmed": 11981,
    "NewDeaths": 0,
    "TotalDeaths": 14,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "3da760be-4a76-407d-b511-901dfe3836f0",
    "Country": "Venezuela (Bolivarian Republic)",
    "CountryCode": "VE",
    "Slug": "venezuela",
    "NewConfirmed": 0,
    "TotalConfirmed": 560800,
    "NewDeaths": 0,
    "TotalDeaths": 5827,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "84155d2f-d47d-443c-962c-b58198e584f5",
    "Country": "Viet Nam",
    "CountryCode": "VN",
    "Slug": "vietnam",
    "NewConfirmed": 546,
    "TotalConfirmed": 11512684,
    "NewDeaths": 0,
    "TotalDeaths": 43169,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "b6aad031-4718-421e-b9cb-290c8a0f2003",
    "Country": "Yemen",
    "CountryCode": "YE",
    "Slug": "yemen",
    "NewConfirmed": 0,
    "TotalConfirmed": 11945,
    "NewDeaths": 0,
    "TotalDeaths": 2159,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "8e91c5d6-1ad2-4d15-a5c2-ad534722c0cd",
    "Country": "Zambia",
    "CountryCode": "ZM",
    "Slug": "zambia",
    "NewConfirmed": 0,
    "TotalConfirmed": 333721,
    "NewDeaths": 0,
    "TotalDeaths": 4019,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  },
  {
    "ID": "318e2d35-bdc4-4294-9fa8-3ddc0749f082",
    "Country": "Zimbabwe",
    "CountryCode": "ZW",
    "Slug": "zimbabwe",
    "NewConfirmed": 0,
    "TotalConfirmed": 257893,
    "NewDeaths": 0,
    "TotalDeaths": 5606,
    "NewRecovered": 0,
    "TotalRecovered": 0,
    "Date": "2022-11-24T14:26:38.309Z",
    "Premium": {}
  }
]`)
