
import Vue from 'vue'
import { mapState, mapActions } from 'vuex'

export default Vue.extend({
  name: 'App',
  computed: {
    ...mapState('Summary', ['loading'])
  },
  methods: {
    ...mapActions('Summary', ['getSummaryDataFromApi'])
  }
})
