
import Vue from 'vue'
import { mapState } from 'vuex'

export default Vue.extend({
  name: 'CountryStatusContent',
  computed: {
    ...mapState('CountryStatus', ['contryName', 'countryStatus'])
  }
})
